import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { GridLoader } from 'react-spinners';
import Header from '../../components/header/Header';
import PartnerLinkCard from '../../components/PartnerLinkCard/PartnerLinkCard';
import { ReactComponent as BackIcon } from '../../media/backIcon.svg';
import TextEditor from '../../components/TextEditor/TextEditor';
import PopupCard from '../../components/PopupCard/PopupCard';
import { fetchProject } from '../../features/project/projectSlice';
import { STATUS, DEFAULT_SPINNER_COLOR } from '../../consts';
import { AMPLITUDE_EVENTS } from '../../consts';
import * as amplitude from '@amplitude/analytics-browser';

import './Project.scss';


export default function ProjectPage() {
  const { projectId } = useParams();
  const projectStatus = useSelector(state => state.project.status);
  const project = useSelector(state => state.project.project);
  const error = useSelector(state => state.project.error)
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectStatus === STATUS.init || (projectStatus !== STATUS.loading && Number(projectId) !== project.id)) {
      amplitude.track(AMPLITUDE_EVENTS.projectView);
      
      dispatch(fetchProject(projectId))
    }
  }, [projectStatus, dispatch, projectId, project.id]);

  const content = useMemo(() => {
    if (!project || !project.keywords || project?.keywords?.length === 0) {
      return <p className="project_keywords-caption">Once you click the button - Get Links, partner links will appear here.</p>;
    }
    
    return project.keywords.filter((keyword) => keyword.deleted !== true).map(
      (keyword, index) => <PartnerLinkCard key={keyword.id} keywordId={index}/>
    );
  }, [project]);

  const isLoading = projectStatus === STATUS.loading || (projectStatus !== STATUS.loading && Number(projectId) !== project.id);

  return (
    <>
      <Header/>

      {(project.text && project.keywords && project.keywords.length === 0) &&
        <PopupCard type="error" msg="Something went wrong. Please improve the content and try again by clicking 'Get Links'"/>
      }
      {(projectStatus === STATUS.error) &&
        <PopupCard type="error" msg={error}/>
      }
  
      <div className="project">
        <div className="project_content">
          <NavLink to='/'  className="icon-btn">
            <BackIcon/>
          </NavLink>
          <div className={`project_text-editor-wrapper ${isLoading ? 'loading' : ''}`}>
          {project.id && Number(projectId) === project.id && <TextEditor />}
          </div>
        </div>
        <div className="project_sidebar">
          <div className="project_sidebar-title">Partner Links</div>
          <div className="project_keywords">
            {isLoading ? <GridLoader color={DEFAULT_SPINNER_COLOR}/> : content}
          </div>
        </div>
      </div>
    </>
  );
};
