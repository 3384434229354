import React, { useState } from "react";
import GoogleLogin from '../Login/GoogleLogin';
import { client } from '../../../../helpers/client';
import PopupCard from '../../../../components/PopupCard/PopupCard';
import {ERRORS, EMAIL_REGEX, PASSWORD_REGEX} from '../../consts';
import Password from "../Password/Password";
import { ReactComponent as Logo } from '../../../../media/logo.svg';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENTS } from '../../../../consts';

export default function Registration(props) {
  const {
    onSwitch,
    emailText,
    setEmailText,
    password,
    setPassword,
    onModeChange,
    withPassword = false,
  } = props;


  const [isLoading, setIsLoading] = useState('');
  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  function validateEmail() {
    if (emailText === '') {
      setErrors((state) => {
        const newState = { ...state };
        newState.email = ERRORS.empty;

        return newState;
      });

      return ERRORS.empty;
    }

    if (!emailText.match(EMAIL_REGEX)) {
      setErrors((state) => {
        const newState = { ...state };
        newState.email = ERRORS.incorrectEmail;

        return newState;
      });

      return ERRORS.incorrectEmail;
    }

    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    return false;
  }

  function validatePassword() {
    if (password === '') {
      setErrors((state) => {
        const newState = { ...state };
        newState.password = ERRORS.empty;

        return newState;
      });

      return ERRORS.empty;
    }

    if (!password.match(PASSWORD_REGEX)) {
      setErrors((state) => {
        const newState = { ...state };
        newState.password = ERRORS.incorrectEmail;
      
        return newState;
      });
    
      return ERRORS.incorrectPassword;
    }

    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    return false;
  }

  function handleEmailChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    setEmailText(e.target.value);
  }

  function handlePasswordChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.password = '';

      return newState;
    });

    setPassword(e.target.value);
  }

  function handleSignUpClick(e) {
    e.preventDefault();
    amplitude.track(AMPLITUDE_EVENTS.clickEmailSignUp);
    if (!validateEmail()) {
      onModeChange();
      amplitude.track(AMPLITUDE_EVENTS.setUpEmailAccountShow);
    }
  }

  async function handleRegister(e) {
    e.preventDefault();

    amplitude.track(AMPLITUDE_EVENTS.clickContinueSignUpEmail);

    if (validatePassword() || validateEmail()) {
      return;
    }
    
    setApiError('');
    setIsLoading(true);

    try {
      const response = await client.post('/register', {
        email: emailText,
        password,
      })
      
      setApiError('');
      onModeChange();
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {(apiError) &&
        <PopupCard type="error" msg={apiError}/>
      }

      <div className="auth_content-header">
        <Logo />
        <button className="button button__light" onClick={onSwitch}>Sign In</button>
      </div>

      <div>
        <div className="auth_content-top">
          <div className="auth_title">Sign up for free you Linkify.ai account</div>
          <p className="auth_subtitle">No credit card required.</p>
        </div>

        <form className="auth_form">
          {!withPassword && (
            <>
              <div className={`auth_form-input ${errors.email ? 'error' : ''}`}>
                <input type="email" value={emailText} onChange={handleEmailChange} placeholder="Enter your email" disabled={isLoading} />
                <div className="auth_form-errors">
                  {errors.email}
                </div>
              </div>
              <div>
                <button onClick={handleSignUpClick} disabled={isLoading}>Sign Up</button>
              </div>
            </>
          )}
          {withPassword && (
            <>
              <div className={`auth_form-input ${errors.email ? 'error' : ''}`}>
                <label>Signing up as</label>
                <input type="email" value={emailText} onChange={handleEmailChange} placeholder="Enter your email" disabled={isLoading} />
                <div className="auth_form-errors">
                  {errors.email}
                </div>
              </div>
              <div className={`auth_form-input ${errors.password ? 'error' : ''}`}>
                <label>Choose a password with at least 8 characters</label>
                <Password value={password} onChange={handlePasswordChange} placeholder="Enter your password" disabled={isLoading} />
                <span className="auth_form-caption">{ERRORS.incorrectPassword}</span>
              </div>
              <div>
                <button onClick={handleRegister} disabled={isLoading}>Continue</button>
              </div>
            </>
          )}
          <div>
            <div className="auth_form-divider">
              <span className="auth_form-divider-text">or</span>
            </div>
          </div>
          <div>
            <GoogleLogin />
          </div>
          <div>
            <span className="auth_form-text">Already have an account? - <button className="auth_form-link" onClick={onSwitch}>Sign In</button></span>
          </div>
        </form>
      </div>


      <div className="auth_therms">
        By Creating an account, you agree to the <a href="https://www.linkify.ai/terms" target="__blank">Terms of Service</a> and <a href="https://www.linkify.ai/privacy" target="__blank">Privacy Policy</a>.
      </div>
    </>
  )
}
