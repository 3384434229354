import React, { useState } from "react";
import GoogleLogin from './GoogleLogin';
import {ERRORS, EMAIL_REGEX } from '../../consts';
import PopupCard from '../../../../components/PopupCard/PopupCard';
import { useDispatch, useSelector } from 'react-redux';
import { loginViaEmail } from '../../../../features/account/accountSlice';
import Password from "../Password/Password";
import { ReactComponent as Logo } from '../../../../media/logo.svg';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENTS } from '../../../../consts';

export default function Login(props) {
  const {
    emailText,
    setEmailText,
    password,
    setPassword,
    onSwitch,
    onModeChange,
    withPassword,
    onForgetClick,
  } = props;

  const dispatch = useDispatch();
  const error = useSelector(state => state.account.error)

  const [isLoading, setIsLoading] = useState('');

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  function validateEmail() {
    if (emailText === '') {
      setErrors((state) => {
        const newState = { ...state };
        newState.email = ERRORS.empty;

        return newState;
      });

      return ERRORS.empty;
    }

    if (!emailText.match(EMAIL_REGEX)) {
      setErrors((state) => {
        const newState = { ...state };
        newState.email = ERRORS.incorrectEmail;

        return newState;
      });

      return ERRORS.incorrectEmail;
    }

    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    return false;
  }

  function validatePassword() {
    if (password === '') {
      setErrors((state) => {
        const newState = { ...state };
        newState.password = ERRORS.empty;

        return newState;
      });

      return ERRORS.empty;
    }


    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    return false;
  }

  function handleEmailChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.email = '';

      return newState;
    });

    setEmailText(e.target.value);
  }

  function handlePasswordChange(e) {
    setErrors((state) => {
      const newState = { ...state };
      newState.password = '';

      return newState;
    });

    setPassword(e.target.value);
  }

  function handleLoginClick(e) {
    e.preventDefault();
    if (validateEmail()) {
      return;
    }

    amplitude.track(AMPLITUDE_EVENTS.clickLogInAuth);
    onModeChange();
  }

  function handleContinueClick(e) {
    e.preventDefault();

    if (validatePassword()) {
      return;
    }


    amplitude.track(AMPLITUDE_EVENTS.clickContinueSignInEmail);

    setIsLoading(true);
    dispatch(loginViaEmail({
      email: emailText,
      password,
    }))
    setIsLoading(false);
  }

  function handleForgetClick(e) {
    e.preventDefault();

    amplitude.track(AMPLITUDE_EVENTS.clickForgotMyPassword);
    onForgetClick();

  }

  return (
    <>
      {(error) &&
        <PopupCard type="error" msg={error}/>
      }

      <div className="auth_content-header">
        <Logo/>
        <button className="button button__light" onClick={onSwitch}>Sign Up</button>
      </div>

      <div>
        {!withPassword && (
          <div className="auth_content-top">
            <div className="auth_title">Welcome back to Linkify.ai</div>
            <p className="auth_subtitle">Log in to your account to get back to your hub for monetizing your content</p>
          </div>
        )}
        {withPassword && (
          <div className="auth_content-top">
            <div className="auth_title">Welcome back to Linkify.ai</div>
            <p className="auth_subtitle">Welcome back <strong>{emailText}</strong></p>
          </div>
        )}
        <form className="auth_form">
          {!withPassword && (
            <>
              <div className={`auth_form-input ${errors.email ? 'error' : ''}`}>
                <input type="email" value={emailText} onChange={handleEmailChange} placeholder="Enter your email" />
                <div className="auth_form-errors">
                  {errors.email}
                </div>
              </div>
              <div>
                <button onClick={handleLoginClick} disabled={isLoading}>Log In</button>
              </div>
            </>
          )}
          {withPassword && (
            <>
              <div className={`auth_form-input ${errors.password ? 'error' : ''}`}>
                <label>Enter the password</label>
                <Password value={password} onChange={handlePasswordChange} placeholder="Password" />
                <div className="auth_form-errors">
                  {errors.password}
                </div>
              </div>
              <div className="auth_form-input auth_form-input__small-top-indent">
                <div className="auth_form-caption auth_form-caption__center">
                  <div>
                    <button className="auth_form-link" onClick={handleForgetClick}>I forgot my password</button>
                  </div>
                  Please send me a recovery email
                </div>
              </div>
              <div>
                <button onClick={handleContinueClick} disabled={isLoading}>Contiune</button>
              </div>
            </>
          )}
          <div>
            <div className="auth_form-divider">
              <span className="auth_form-divider-text">or</span>
            </div>
          </div>
          <div>
            <GoogleLogin />
          </div>
          <div>
            <span className="auth_form-text">Don’t have a account? - <button className="auth_form-link" onClick={onSwitch}>Sign Up</button></span>
          </div>
        </form>
      </div>
      <div></div>
    </>
  )
}
