import React from "react";
import { NavLink, Navigate } from "react-router-dom";
import { ReactComponent as LinkIcon } from '../../media/link.svg';

import './ProjectCard.scss';

export default function ProjectCard(project) {
  return (
    <NavLink to={'/projects/'+project.project.id+'/'} className="project-card">
      <div className="project-card_title">{project.project.name}</div>
      <div className="project-card_description">
        {project.project.short_text}
      </div>
      <div className="project-card_footer">
        <LinkIcon/>
        {project.project.count_links} links
      </div>
    </NavLink>
  )
}