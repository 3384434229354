import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ClipLoader } from 'react-spinners';
import { ReactComponent as KeywordTrashIcon } from '../../media/keywordTrash.svg';
import { ReactComponent as SelectedIcon } from '../../media/selectedIcon.svg';
import { ReactComponent as MoreLinkIcon } from '../../media/moreLinkIcon.svg';
import { ReactComponent as SearchLinkIcon } from '../../media/searchLinkIcon.svg';
import { updateKeyword } from '../../features/project/projectSlice';
import { STATUS } from '../../consts'

import './PartnerLinkCard.scss';

function renderLink(link, isSelected, linkKey, action) {
  return (
    <div className={"link-block " + (isSelected ? "selected" : "")} onClick={action} key={linkKey}>
      <div className="link-content">
        <img className="link-image" src={link.image_url} referrerPolicy="no-referrer"/>
        <div className="link-info">
          <div className="link-text">
            {link.product_title}
          </div>
          <div className="link">
            <a href={link.url} target="_blank">amazon.com</a>
          </div>
        </div>
      </div>
      <div className="link-selected-icon">
        <SelectedIcon/>
      </div>
    </div>
  )
}


function ShortPartnerLinkCard(keyword, setCardType, dispatch) {
  return (
    <div className="keyword-block">
      <div className ="keyword" onClick={() => { setCardType('detailed') }}>
        {keyword.label}
        <KeywordTrashIcon className="keyword-trash" onClick={() => {
          dispatch(updateKeyword({'id': keyword.id, 'deleted': true}))
        }}/>
      </div>
      <div className="link-wrapper">
        {renderLink(keyword.links[0], false, keyword.id + '-' + 0, null)}
      </div>
    </div>
  )
}


function DetailedPartnerLinkCard(keyword, setCardType, dispatch) {
  return (
    <div className="keyword-block selected">
      <div className ="keyword" onClick={() => {setCardType('short')}}>
        {keyword.label}
        <KeywordTrashIcon className="keyword-trash" onClick={() => {
          dispatch(updateKeyword({'id': keyword.id, 'deleted': true}))
        }}/>
      </div>
      <div className="link-wrapper">
        {keyword.links.map((link, index) => {
          var linkKey = keyword.id + '-' + index
          if (index===0){
            return renderLink(link, true, linkKey, null)
          }
          if (index >= 3) {
            return null
          }
          return renderLink(link, false, linkKey, () => {
            let newLinks = [keyword.links[index]].concat(
              keyword.links.filter((link, i) => {return i != index}
            ))
            dispatch(updateKeyword({'id': keyword.id, 'links': newLinks}))
          })
        })}
      </div>
      <div className="keyword-control">
        <div className="btn btn-text" onClick={() => {setCardType('full')}}><MoreLinkIcon/>More Links</div>
        <div className="btn-container">
          <button className="btn btn-icon" onClick={() => {setCardType('search')}}>
            <SearchLinkIcon/>
          </button>
        </div>
      </div>
    </div>
  )
}


function FullPartnerLinkCard(keyword, setCardType, dispatch) {
  return (
    <div className="keyword-block selected">
      <div className ="keyword" onClick={() => {setCardType('short')}}>
        {keyword.label}
        <KeywordTrashIcon className="keyword-trash" onClick={() => {
          dispatch(updateKeyword({'id': keyword.id, 'deleted': true}))
        }}/>
      </div>
      <div className="link-wrapper">
        {keyword.links.map((link, index) => {
          var linkKey = keyword.id + '-' + index
          if (index===0){
            return renderLink(link, true, linkKey, null)
          }
          return renderLink(link, false, linkKey, () => {
            let newLinks = [keyword.links[index]].concat(
              keyword.links.filter((link, i) => {return i != index}
            ))
            dispatch(updateKeyword({'id': keyword.id, 'links': newLinks}))
          })
        })}
      </div>
      <div className="keyword-control">
        <div className="btn btn-text" onClick={() => {setCardType('detailed')}}><MoreLinkIcon/>Less Links</div>
        <div className="btn-container">
          <button className="btn btn-icon" onClick={() => {setCardType('search')}}>
            <SearchLinkIcon/>
          </button>
        </div>
      </div>
    </div>
  )
}


function SearchPartnerLinkCard(keyword, setCardType, dispatch, searchText, setSearchText, debounced) {
  var content;
  if (keyword.status === STATUS.loading) {
    content = <ClipLoader/>
  } else if (keyword.status === STATUS.error) {
    content = (
      <span id="result-placeholder" className="result-placeholder">
        {keyword.error}
      </span>
    )
  } else if (!searchText) {
    content = (
      <span id="result-placeholder" className="result-placeholder">
        Write something
      </span>
    )
  } else {
    content = (
      <div id="search-results" className="link-wrapper">
        {keyword.links.map((link, index) => {
          var linkKey = keyword.id + '-' + index + '-search';
          return renderLink(link, false, linkKey, null)
        })}
      </div>
    )
  }
  return (
    <div className="keyword-block selected">
      <div className ="keyword" onClick={() => {setCardType('short')}}>
        {keyword.label}
        <KeywordTrashIcon className="keyword-trash" onClick={() => {
          dispatch(updateKeyword({'id': keyword.id, 'deleted': true}))
        }}/>
      </div>
      <div className="search-container" id="search-container">
        <div className="search-body">
          <SearchLinkIcon width="16" height="17"/>
          <input
            className="search-input"
            type="text"
            placeholder="Search by other words"
            id="search-input"
            value={searchText}
            onChange={e => {
              let data = e.target.value;
              setSearchText(data);
              debounced(data)
            }}
          />
          <MoreLinkIcon onClick={() => {setCardType("detailed")}}/>
        </div>
        <div className="link-wrapper">
          {content}
        </div>
      </div>
    </div>
  )
}

export default function PartnerLinkCard(keywordId) {
  const [cardType, setCardType] = useState('short');
  const dispatch = useDispatch()
  const keyword = useSelector(state => state.project.project.keywords[keywordId.keywordId]);
  const keywordStatus = useSelector(state => state.project.project.keywords[keywordId.keywordId].status);
  const [searchText, setSearchText] = useState('');
  const debounced = useDebouncedCallback(
    (searchText) => {
      dispatch(updateKeyword({'id': keyword.id, 'name': searchText}));
    },
    1000
  );

  if (cardType == 'short') {
    return ShortPartnerLinkCard(keyword, setCardType, dispatch)
  } else if (cardType == 'detailed') {
    return DetailedPartnerLinkCard(keyword, setCardType, dispatch)
  } else if (cardType == 'full') {
    return FullPartnerLinkCard(keyword, setCardType, dispatch)
  } else if (cardType == 'search') {
    return SearchPartnerLinkCard(keyword, setCardType, dispatch, searchText, setSearchText, debounced)
  } else {
    return null
  }
}