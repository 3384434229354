import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../features/account/accountSlice'
import { history } from '../../helpers/history';


export default function PrivateRoute() {
  const authStatus = useSelector(state => state.account.status);
  const isAuthenticated = useSelector(state => state.account.isAuthenticated);
  const isConfirmed = useSelector(state => state.account.isConfirmed);
  const dispatch = useDispatch()

  useEffect(() => {
    if (authStatus === 'init') {
      dispatch(getAccount())
    }
  }, [authStatus, dispatch])

  if (authStatus === 'init') {
    return null
  }
  if (authStatus === 'succeeded' && isAuthenticated && isConfirmed) {
    return <Outlet />
  } else {
    return <Navigate to="/auth" state={{ from: history.location }} />
  }
}