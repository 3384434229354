import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import { useDebouncedCallback } from 'use-debounce';
import { updateProject, updateKeywords } from '../../features/project/projectSlice'
import { STATUS } from '../../consts'
import { AMPLITUDE_EVENTS } from '../../consts';
import * as amplitude from '@amplitude/analytics-browser';
import {replaceKeywordToLinkInText, removeLinks } from '../../helpers/link'

const initEditor = (text, setText, ejInstance, saveContent) => {
  const editor = new EditorJS({
    autofocus: true,
    holder: 'editor',
    placeholder: 'Type or past your text here',
    data: text,
    onReady: async () => {
      ejInstance.current = editor;
      //editor.api.caret.setToLastBlock('end', 0);
    },
    onChange: async () => {
      let content = await editor.saver.save();
      setText(content);
      saveContent(content);
    },
    tools: {
      header: Header,
    },
  });
};

export default function TextEditor() {
  const { projectId } = useParams();
  const projectStatus = useSelector(state => state.project.status);
  const project = useSelector(state => state.project.project);
  const initialProjectText = useSelector(state => state.project.project.text);

  const [text, setText] = useState(Number(projectId) === Number(project.id) ? initialProjectText : '');
  const dispatch = useDispatch();
  const saveContent = useDebouncedCallback(
    (newText) => dispatch(updateProject({projectId: projectId, newText: JSON.stringify(newText)})), 2000
  );

  const ejInstance = useRef(null);

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor(text, setText, ejInstance, saveContent);
    }
    
    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  useEffect(() => {
    if (ejInstance.current !== null && text) {
      let newText = removeLinks(text);
      project.keywords.map((keyword) => {
        newText = replaceKeywordToLinkInText(newText, keyword.label, keyword.links[0].url)
      });

      ejInstance?.current?.render(newText);
    }
    
  }, [project.keywords]);

  async function handleCopyClick() {
    const e = document.querySelector("#editor .codex-editor__redactor");
    const textNode = e.childNodes[0];
    const r = document.createRange();
    const startIndex = 0;
    r.setStart(e, startIndex);
    r.setEnd(e, e.children.length);
    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(r);
    document.execCommand("copy");

    amplitude.track(AMPLITUDE_EVENTS.clickCopyText);
  }

  async function handleGetLinksClick() {
    dispatch(updateKeywords({projectId: projectId, newText: text}));
    amplitude.track(AMPLITUDE_EVENTS.clickGetLinks);
  }
  
  return (
      <div className="editor-body">
        <div className="editor-input" id="editor"></div>
        {([STATUS.succeeded, STATUS.error].includes(projectStatus)) &&
          <div className="editor-control not-scrolled-bottom" id="editor-control">
            <button className="btn" onClick={handleCopyClick} disabled={text?.blocks?.length === 0}>Copy text</button>
            <div className="btn-container">
              <button className="btn btn-primary" onClick={handleGetLinksClick} disabled={!text?.blocks?.length || text?.blocks?.length === 0}>Get Links</button>
            </div>
          </div>
        }
      </div>
    )
  }
