import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { addProject } from '../../features/projects/projectsSlice'
import { ReactComponent as AddProjectIcon } from '../../media/addProject.svg';
import { history } from '../../helpers/history';
import { AMPLITUDE_EVENTS } from '../../consts';
import * as amplitude from '@amplitude/analytics-browser';

import './AddNewProjectCard.scss';

export default function AddNewProjectCard(project) {
  const addingProjectStatus = useSelector(state => state.projects.statusAddingProject);
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.projects);
  const navigate = useNavigate();

  if (addingProjectStatus === 'loading') {
    return (
      <a className="add-project"/>
    )
  }
  
  if (addingProjectStatus === 'succeeded') {
    navigate("/projects/"+projects[0].id + '/',  {state: { from: history.location }})
  }

  const handleClick = () => {
    amplitude.track(AMPLITUDE_EVENTS.clickCreateProject);

    dispatch(addProject('Untitled'));
  }

  return (
    <a className="add-project" onClick={handleClick}>
      <AddProjectIcon/>
    </a>
  )
}