import React, { useState, useEffect } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './Auth.scss'
import AuthImage from './images/auth.png';
import EmailConfirmation from './components/EmailConfirmation/EmailConfirmation.jsx';
import Login from './components/Login/Login.jsx';
import Registration from './components/Registration/Registration.jsx';
import ForgetPassword from './components/ForgetPassword/ForgetPassword.jsx';
import { history } from '../../helpers/history';
import { AMPLITUDE_EVENTS } from '../../consts'

const MODES = {
  register: 'register',
  registerContinue: 'registerContinue',
  registerConfirm: 'registerConfirm',
  login: 'login',
  loginContinue: 'loginContinue',
  forgetPassword: 'forgetPassword',
  resetPassword: 'resetPassword',
}

export default function AuthPage() {
  const authStatus = useSelector(state => state.account.status);
  const isAuthenticated = useSelector(state => state.account.isAuthenticated);
  const isConfirmed = useSelector(state => state.account.isConfirmed);
  const surveyShown = useSelector(state => state.account.surveyShown);

  const [emailText, setEmailText] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState(window.location.search.includes('signup') ? MODES.register : MODES.login);

  function switchToLogin(e) {
    e.preventDefault();
    setMode(MODES.login);
  }

  function switchToRegister(e) {
    e.preventDefault();
    setMode(MODES.register);
  }

  useEffect(() => {
    if (mode === MODES.login) {
      amplitude.track(AMPLITUDE_EVENTS.signInPageShow);
    }
    
    if (mode === MODES.register) {
      amplitude.track(AMPLITUDE_EVENTS.authPageShow);
    }
  }, [mode]);

  if (authStatus === 'loading') {
    return null
  }
  
  if (authStatus === 'succeeded' && isAuthenticated && isConfirmed) {
    if (surveyShown) {
      return <Navigate to="/" state={{ from: history.location }} />
    }
    return <Navigate to="/surveys/about-me/" state={{ from: history.location }} />
  }

  return (
    <div className={`auth ${mode === MODES.register ? 'auth__showcase' : ''}`}>
      <div className="auth_showcase">
        <img src={AuthImage} alt="" />
        <div>
          <span className="auth_showcase-title">Create your free account</span>
          <ul className="auth_showcase-list">
            <li>Monetize your content effortlessly.</li>
            <li>Bloggers, Let Every Word You Write Pay Off!</li>
            <li>Email Storytellers, Turn Every Newsletter into a Cash Flow!</li>
            <li>Write, Highlight, Earn – It’s That Simple with <a href="https://linkify.ai" target="_blank">Linkify.ai</a> !</li>
          </ul>
        </div>
      </div>

      <div className="auth_content-wrapper">
        <div className={`auth_content auth_content__static ${mode === MODES.register ? 'active' : ''}`}>
          <Registration
            emailText={emailText}
            setEmailText={setEmailText}
            password={password}
            setPassword={setPassword}
            onSwitch={switchToLogin}
            onModeChange={() => setMode(MODES.registerContinue)}
          />
        </div>

        <div className={`auth_content ${mode === MODES.registerContinue ? 'active' : ''}`}>
          <Registration
            emailText={emailText}
            setEmailText={setEmailText}
            password={password}
            setPassword={setPassword}
            onSwitch={switchToLogin}
            onModeChange={() => setMode(MODES.registerConfirm)}
            withPassword
          />          
        </div>

        <div className={`auth_content ${mode === MODES.registerConfirm ? 'active' : ''}`}>
          <EmailConfirmation email={emailText} onSwitch={switchToLogin} />
        </div>

        <div className={`auth_content ${mode === MODES.login ? 'active' : ''}`}>
          <Login
            emailText={emailText}
            setEmailText={setEmailText}
            password={password}
            setPassword={setPassword}
            onSwitch={switchToRegister}
            onModeChange={() => setMode(MODES.loginContinue)} />
        </div>

        <div className={`auth_content ${mode === MODES.loginContinue ? 'active' : ''}`}>
          <Login
            emailText={emailText}
            setEmailText={setEmailText}
            password={password}
            setPassword={setPassword}
            onSwitch={switchToRegister}
            onForgetClick={() => setMode(MODES.forgetPassword)}
            withPassword
          />
        </div>

        <div className={`auth_content ${mode === MODES.forgetPassword ? 'active' : ''}`}>
          <ForgetPassword emailText={emailText} />
        </div>
      </div>

    </div>
  );
}
